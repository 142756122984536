<template>
  <section id="workflow-users-list">
    <div class="vue-data-table-default p-4">
      <!-- <el-button
        type="text"
        icon="el-icon-setting"
        @click="showSettingsDialog"
        style="margin-bottom: 16px;"
        title="Configure Columns"
      >
        Configure Columns
      </el-button> -->

      <data-tables-server
        :data="data"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
      >
        <el-table-column
          v-for="column in columns.filter((col) =>
            selectedFields.includes(col.key)
          )"
          :key="column.key"
          :label="column.label"
          :prop="column.key"
          :width="200"
        >
          <template #default="{ row }">
            <div v-if="column.key === 'picture'">
              <img
                :src="row[column.key]"
                alt="Image"
                style="
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  border-radius: 8px;
                "
              />
            </div>
            <div v-else-if="column.type === 'file'">
              <el-button
                type="primary"
                @click="handleDownload(row, column)"
                size="mini"
              >
                <i class="el-icon-download"></i>
              </el-button>
              <el-button
                type="info"
                @click="handleView(row, column)"
                size="mini"
              >
                <i class="el-icon-view"></i>
              </el-button>
            </div>
            <!-- <div v-else-if="column.key === 'caption'|| column.key ==='description'">
      <div>
        <span v-if="isContentLong(row[column.key]) && !row.showFullCaption">
          {{ row[column.key].slice(0, 200) }}...
          <el-button 
            type="text" 
            @click="toggleCaption(row)"
            style="color: #409EFF;"
          >
            See More
          </el-button>
        </span>
        <span v-else>
          {{ row[column.key] }}
          <el-button 
          v-if="row.showFullCaption && (column.key === 'caption' || column.key === 'description')" 
            type="text" 
            @click="toggleCaption(row)"
            style="color: #409EFF;"
          >
            See Less
          </el-button>
        </span>
      </div> -->
            <!-- </div> -->
            <div v-else-if="row[column.key] && isContentLong(row[column.key])">
              <div>
                <span v-if="!row.showFullCaption">
                  {{ row[column.key].split(" ").slice(0, 10).join(" ") }}...
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See More
                  </el-button>
                </span>
                <span v-else>
                  {{ row[column.key] }}
                  <el-button
                    type="text"
                    @click="toggleCaption(row)"
                    style="color: #409eff"
                  >
                    See Less
                  </el-button>
                </span>
              </div>
            </div>

            <div v-else-if="column.key === 'comments'">
              <span>
                {{ getCommentText(row) }}
              </span>
            </div>
            <div v-else>
              {{ row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </data-tables-server>
    </div>
    <dialog-component
      :title="'Select Columns'"
      :visible="isSettingsDialogVisible"
      @before-close="isSettingsDialogVisible = false"
      :containerWidth="'40%'"
      :containerMaxHeight="'70%'"
      :containerMinHeight="'30%'"
       
    >
      <el-checkbox-group v-model="selectedFields">
        <el-checkbox
          v-for="column in availableColumns"
          :key="column.key"
          :label="column.key"
        >
          {{ column.label }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmSelection">Confirm</el-button>
        <el-button @click="isSettingsDialogVisible = false">Cancel</el-button>
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "IntegrationTable",
  props: {
    componentData: Object,
    integrationDetails: Object,
    customFilters: Array,
  },
  mixins: [],
  data() {
    return {
      total: 0,
      rows: [],
      currentPage: 1,
      pageSize: 0,
      data: [],
      loading: false,
      loadingText: "Loading...",
      columns: [],
      availableColumns: [],
      selectedFields: [],
      isSettingsDialogVisible: false, //
      showTable: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    await this.getNecessaryInfo();
    await this.fetchIntegrations();
    this.setDefaultColumns();
  },
  methods: {
    setDefaultColumns() {
      this.availableColumns =
        this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
        [];
      this.selectedFields = this.availableColumns.map((column) => column.key);
      this.fetchAllData();
    },

    showSettingsDialog() {
      this.prepareAvailableColumns();
      this.isSettingsDialogVisible = true;
    },
    prepareAvailableColumns() {
      this.availableColumns =
        this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
        [];
    },
    confirmSelection() {
      this.fetchAllData(); 
      this.isSettingsDialogVisible = false;
    },

    isContentLong(content) {
      return content && content.length > 100;
    },
    toggleCaption(row) {
      this.$set(row, "showFullCaption", !row.showFullCaption);
    },
    getCommentText(row) {
      if (row.comments && Array.isArray(row.comments.data)) {
        return row.comments.data.map((comment) => comment.text).join(", ");
      }
      return "";
    },
    async loadData(data) {
      if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllData();
      }
    },
    async getNecessaryInfo() {
      this.prepareColumns();
      this.fetchAllData();
    },
    prepareColumns() {
      this.columns = [];
      if (this.integrationDetails?.matchingEvents?.settings?.availableColumns) {
        this.columns =
          this.integrationDetails.matchingEvents.settings.availableColumns ||
          [];
      }
    },
    async fetchIntegrations() {
      let params = {
        limit: 10,
        page: 1,
      };
      let integrationData = await postAPICall("GET", `integrations`, params);
      return integrationData;
    },
    //     async fetchAllData() {
    //       console.log("integrationDetails", this.integrationDetails);
    //       console.log("componentData", this.componentData);
    //       console.log("customFilters", this.customFilters);
    //       let savedDependencies=await this.fetchIntegrations();
    //       let connection_id =
    //         this.integrationDetails?.matchedApplication?.integration
    //           ?.connection_id || "";
    //           if (connection_id) {
    //   const matchingEntries = savedDependencies.data.filter(entry => entry.connection_id === connection_id);
    //   if (matchingEntries.length > 0) {
    //       console.log("Matching Entries:", matchingEntries);
    //       const dependencies = [];
    //       matchingEntries.forEach(entry => {
    //   if (entry.dependencies && Object.keys(entry.dependencies).length > 0) {
    //     dependencies.push(entry.dependencies);
    //   }
    // });

    //       if (dependencies.length > 0) {
    //         console.log("Dependencies found:", dependencies);
    //       } else {
    //         console.log("No dependencies found in matching entries.");
    //       }
    //     } else {
    //       console.log("No matching entries found.");
    //     }
    //   } else {
    //     console.log("No connection ID available.");
    //   }
    //       if (!connection_id) {
    //         this.$notify({
    //           title: "Error",
    //           message:
    //             "Connection ID is missing. Please ensure the integration is properly set up.",
    //           type: "error",
    //         });
    //         this.loading = false;
    //         return;
    //       }
    //       this.loading = true;
    //       const availableColumns =
    //   this.integrationDetails?.matchingEvents?.settings?.availableColumns || [];

    // const fields =
    //   this.integrationDetails?.matchingEvents?.settings?.supportType === 'table' && availableColumns.length > 0
    //     ? availableColumns.map((e) => e.key)
    //     : undefined;
    //       try {
    //         const pagination = {
    //     page: this.currentPage ,
    //     limit: this.pageSize
    //   };
    //         const response = await postAPICall(
    //           "POST",
    //           "integrations/service/fetch-data",
    //           {
    //             asset: this.integrationDetails?.matchingEvents?.asset,
    //             provider: this.integrationDetails?.matchedApplication?.key,
    //             // field: this.integrationDetails?.matchingEvents?.eventKey,
    //             connectionId: connection_id,
    //             dependencies: this.componentData.dependencies || dependencies,
    //             ...this.componentData.dates,
    //             fields: fields,
    //             pagination:pagination
    //           }
    //         );

    //         console.log("API Response:", response);

    //         this.data = response.data.rows || [];
    //         if (this.data.length) {
    //           this.total = response.data.metadata.total_records || 0;
    //           this.loading = true;
    //         }
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //         this.$notify({
    //           title: "Error",
    //           message: "Failed to fetch data. Please try again.",
    //           type: "error",
    //         });
    //       } finally {
    //         this.loading = false;
    //       }
    //     },
    async fetchAllData() {
      this.loading = true;
      let connection_id =
        this.integrationDetails?.matchedApplication?.integration
          ?.connection_id || "";
      if (!connection_id) {
        this.$notify({
          title: "Error",
          message:
            "Connection ID is missing. Please ensure the integration is properly set up.",
          type: "error",
        });
        this.loading = false;
        return;
      }
      let dependencies = this.componentData.dependencies || [];
      try {
        if (!dependencies.length) {
          let savedDependencies = await this.fetchIntegrations();
          const matchingDep = savedDependencies.data.filter(
            (entry) => entry.connection_id === connection_id
          );

          if (matchingDep.length > 0) {
            dependencies = matchingDep
              .map((entry) => entry.dependencies)
              .filter((dep) => dep && Object.keys(dep).length > 0);
            dependencies = Array.from(
              new Set(dependencies.map((dep) => JSON.stringify(dep)))
            ).map((item) => JSON.parse(item));
          } else {
            console.log("No matching entries found.");
          }
        }

        const availableColumns =
          this.integrationDetails?.matchingEvents?.settings?.availableColumns ||
          [];

        // const fields =
        //   this.integrationDetails?.matchingEvents?.settings?.supportType === 'table' && availableColumns.length > 0
        //     ? availableColumns.map((e) => e.key)
        //     : undefined;
        const fields =
          this.selectedFields && this.selectedFields.length > 0
            ? this.selectedFields
            : this.integrationDetails?.matchingEvents?.settings?.supportType ===
                "table" && availableColumns.length > 0
            ? availableColumns.map((e) => e.key)
            : undefined;
        const pagination = {
          page: this.currentPage,
          limit: this.pageSize,
        };
        const relevantDependencies =
          dependencies.length > 0 ? dependencies[0] : {};
        const response = await postAPICall(
          "POST",
          "integrations/service/fetch-data",
          {
            asset: this.integrationDetails?.matchingEvents?.asset,
            provider: this.integrationDetails?.matchedApplication?.key,
            // field: this.integrationDetails?.matchingEvents?.eventKey,
            connectionId: connection_id,
            dependencies:
              this.componentData.dependencies || relevantDependencies,
            ...this.componentData.dates,
            fields: fields,
            pagination: pagination,
          }
        );

        this.data = response.data.rows || [];
        if (this.data.length) {
          this.total = response.data.metadata.total_records || 0;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$notify({
          title: "Error",
          message: "Failed to fetch data. Please try again.",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    async handleDownload(row, column) {
      try {
        let response = await this.downloadMethod(row, column);
        let fileURL = response.data.url;
        if (response.data.type == "url") {
          let blobResponse = await fetch(fileURL, {
            method: "GET",
            responseType: "blob",
          });
          const blob = await blobResponse.blob();
          fileURL = window.URL.createObjectURL(blob);
        }
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = response.data.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.fileLoading = false;
      }
    },
    async handleView(row, column) {
      try {
        let response = await this.downloadMethod(row, column);
        let fileUrl = response.data.url;
        let fileExtension = response.data.extension;
        if (fileExtension === ".pdf") {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<embed src="${fileUrl}" type="application/pdf" width="100%" height="100%"></embed>`;
        } else if (
          [".jpg", ".jpeg", ".png", ".gif", ".bmp"].includes(fileExtension)
        ) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<img src="${fileUrl}" width="100%" height="100%" />`;
        } else if ([".mp4", ".webm", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<video src="${fileUrl}" width="100%" height="100%" controls></video>`;
        } else if ([".mp3", ".wav", ".ogg"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<audio src="${fileUrl}" controls></audio>`;
        } else if ([".html", ".txt", ".csv"].includes(fileExtension)) {
          let newTab = window.open();
          newTab.document.body.innerHTML = `<iframe src="${fileUrl}" width="100%" height="100%"></iframe>`;
        } else {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = response.data.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        this.fileLoading = false;
      }
    },
    async downloadMethod(row, column) {
      try {
        let connection_id =
          this.integrationDetails?.matchedApplication?.integration
            ?.connection_id || "";
        let requiredKeys = {};
        column.inputFields.forEach((key) => {
          requiredKeys[`${key}`] = row[key];
        });
        let payload = {
          provider: this.integrationDetails.matchedApplication.key,
          asset: this.integrationDetails.matchingEvents.asset,
          connectionId: connection_id,
          requiredKeys: requiredKeys,
        };
        this.fileLoading = true;
        let response = await postAPICall(
          "POST",
          "integrations/service/download-file",
          payload
        );
        this.fileLoading = false;
        return response;
      } catch (err) {
        this.fileLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}
.expired-div {
  color: #dc3545;
}
.dull-text {
  color: #888;
  opacity: 0.8;
  overflow-wrap: break-word;
  word-break: normal;
}
.log-expired {
  color: #dc3545;
}
.resend-form-style {
  width: 70px;
  height: 25px;
}
</style>
